import {useEffect, useState} from "react";
import {Link, useParams, useSearchParams} from "react-router-dom";
import Config from "../config";
import Skeleton from 'react-loading-skeleton';
import { Tooltip } from 'react-tooltip';

import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";

import Slider from "react-slick";

function smallArticleItem(){
    return (
        <div className="item">
            <div className="item-head">
                <div className="avatar">
                    <Skeleton width="55px" height="55px" />
                </div>
                <div className="info">
                    <h3 className="title"><Skeleton width="200px" height="23px" /></h3>
                    <div className="stats">
                        <Skeleton width="112px" height="20px" />
                    </div>
                </div>
            </div>
        </div>
    )
}

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
        />
    );
}

const slickSettings = {
    dots: false,
    infinite: false,
    slidesToShow: 2.5,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
};

export default function Article() {
    const [searchParams] = useSearchParams();
    const [ns, setNs] = useState(searchParams.get("ns") ? searchParams.get("ns") : '');
    const {slug} = useParams();
    useEffect(() => {
        loadData(slug)
    }, [slug]);


    const [object, setObject] = useState({category: {}, default_value: {}, related_articles: [], popular_articles: [], images_list : []});
    const [activeTab, setActiveTab] = useState('information');

    const screenshots = [
        { large: 'https://assets.nintendo.com/image/upload/ar_16:9,b_auto:border,c_lpad/b_white/f_auto/q_auto/dpr_1.0/c_scale,w_1500/ncom/software/switch/70010000068688/87e8aa5f1fdc950b88eae7d7c62ed185c8a6373c845090bbdb2e2cf039b38da1', thumb: 'https://assets.nintendo.com/image/upload/ar_16:9,b_auto:border,c_lpad/b_white/f_auto/q_auto/dpr_1.0/c_scale,w_1500/ncom/software/switch/70010000068688/87e8aa5f1fdc950b88eae7d7c62ed185c8a6373c845090bbdb2e2cf039b38da1', caption: '' },
        { large: 'https://assets.nintendo.com/image/upload/ar_16:9,b_auto:border,c_lpad/b_white/f_auto/q_auto/dpr_1.0/c_scale,w_1500/ncom/software/switch/70010000068688/7f1845a5e8d8ec895ad188a6b0f46b59036a363db1b2d9b79d23b913b0b9ab8a', thumb: 'https://assets.nintendo.com/image/upload/ar_16:9,b_auto:border,c_lpad/b_white/f_auto/q_auto/dpr_1.0/c_scale,w_1500/ncom/software/switch/70010000068688/7f1845a5e8d8ec895ad188a6b0f46b59036a363db1b2d9b79d23b913b0b9ab8a', caption: '' },
        { large: 'https://assets.nintendo.com/image/upload/ar_16:9,b_auto:border,c_lpad/b_white/f_auto/q_auto/dpr_1.0/c_scale,w_1500/ncom/software/switch/70010000068688/e3024df668cdead60e62865924652c811323b0e7c46f51567fe80907f2637cc4', thumb: 'https://assets.nintendo.com/image/upload/ar_16:9,b_auto:border,c_lpad/b_white/f_auto/q_auto/dpr_1.0/c_scale,w_1500/ncom/software/switch/70010000068688/e3024df668cdead60e62865924652c811323b0e7c46f51567fe80907f2637cc4', caption: '' },
        { large: 'https://assets.nintendo.com/image/upload/ar_16:9,b_auto:border,c_lpad/b_white/f_auto/q_auto/dpr_1.0/c_scale,w_1500/ncom/software/switch/70010000068688/e1ee4f690cec788fc2a75e85ddc12f6d7ef69501bbb362ad918e1ffcbc14936f', thumb: 'https://assets.nintendo.com/image/upload/ar_16:9,b_auto:border,c_lpad/b_white/f_auto/q_auto/dpr_1.0/c_scale,w_1500/ncom/software/switch/70010000068688/e1ee4f690cec788fc2a75e85ddc12f6d7ef69501bbb362ad918e1ffcbc14936f', caption: '' },
        { large: 'https://assets.nintendo.com/image/upload/ar_16:9,b_auto:border,c_lpad/b_white/f_auto/q_auto/dpr_1.0/c_scale,w_1500/ncom/software/switch/70010000068688/c77b1b26934fa04c01bbadcd5081b7a2cbfe787f4cd7d8a8e59445f67beb73c3', thumb: 'https://assets.nintendo.com/image/upload/ar_16:9,b_auto:border,c_lpad/b_white/f_auto/q_auto/dpr_1.0/c_scale,w_1500/ncom/software/switch/70010000068688/c77b1b26934fa04c01bbadcd5081b7a2cbfe787f4cd7d8a8e59445f67beb73c3', caption: '' },
    ];

    const loadData = (_slug)=>{
        let fullDomain = Config.apiDomain;
        fetch(Config.apiDomain + "article/details/" + _slug + '?ns=' + ns)
            .then((res) => res.json())
            .then((json) => {
                if(json.data){
                    setObject(json.data);
                    document.title = json.data.title + ' | ' + Config.companyName;
                }

            })
            .catch(error => {
                window.location.assign("/");
            })
        ;
    }

    const [downloadText, setDownloadText] = useState('Download Now');
    const [downloadClass, setDownloadClass] = useState('');
    function downloadFile(download_url){
        window.location.href = download_url;
        setDownloadText('Downloading, please wait...');
        setDownloadClass('downloading-dl');
        setTimeout(
            function() {
                setDownloadText('Download Now');
                setDownloadClass('');
            }
                .bind(this),
            1000
        );
    }

    const [isFixedDownload, setIsFixedDownload] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            if (scrollTop > 100) {
                setIsFixedDownload(true);
            } else {
                setIsFixedDownload(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpenLightbox, setIsOpenLightbox] = useState(false);

    return (
        <main id="details">
            <div className="container">
                <Tooltip anchorSelect=".languages-list-link" place="top" style={{maxWidth: '500px', zIndex: 9999}}>
                    <p className="fw-bold text-center">Supported languages:</p>
                    <ul className="languages-list">
                        <li>Arabic</li>
                        <li>Bengali</li>
                        <li>Chinese</li>
                        <li>Czech</li>
                        <li>Danish</li>
                        <li>Dutch</li>
                        <li>English</li>
                        <li>Filipino</li>
                        <li>Finnish</li>
                        <li>French</li>
                        <li>German</li>
                        <li>Greek</li>
                        <li>Hindi</li>
                        <li>Italian</li>
                        <li>Japanese</li>
                        <li>Korean</li>
                        <li>Norwegian</li>
                        <li>Polish</li>
                        <li>Portuguese</li>
                        <li>Spanish</li>
                        <li>Swedish</li>
                        <li>Thai</li>
                        <li>Turkish</li>
                        <li>Urdu</li>
                    </ul>
                </Tooltip>

                <div className="row">
                    <div className="col-8 col-left">
                        <section className="d-flex flex-row overall-info-section">
                            <div className="avatar">
                                { object.avatar ? (
                                <img src={object.avatar} alt=""/>
                                ) : (
                                    <Skeleton width={"200px"} height={"200px"} />
                                )}
                            </div>
                            <div className="info">
                                <h1 className="main-title">{object.title || <Skeleton width={"200px"} />}</h1>
                                <div className="stats-info d-flex flex-row align-items-center">
                                    { object.star_point ? (
                                    <div className="item d-flex flex-column text-center">
                                        <div className="count">
                                            <span className="number">{object.star_point}</span>&nbsp;<i className="bi bi-star-fill"></i>
                                        </div>
                                        <div className="text">
                                            Rating
                                        </div>
                                    </div>
                                    ) : (
                                        <Skeleton width={"48px"} height={"48px"} />
                                    )}
                                    <div className="seperator">
                                        <div className="line"></div>
                                    </div>
                                    { object.download_count ? (
                                    <div className="item d-flex flex-column text-center">
                                        <div className="count">
                                            <span className="number">{object.download_count}</span>
                                        </div>
                                        <div className="text">
                                            Downloads
                                        </div>
                                    </div>
                                    ) : (
                                        <Skeleton width={"76px"} height={"48px"} />
                                    )}
                                    <div className="seperator">
                                        <div className="line"></div>
                                    </div>
                                    { object.category.title ? (
                                    <div className="item d-flex flex-column text-center">
                                        <div className="count">
                                            <span className="link"><Link to={object.category.link}>{object.category.title}</Link></span>
                                        </div>
                                        <div className="text">
                                            Category
                                        </div>
                                    </div>
                                    ) : (
                                        <Skeleton width={"80px"} height={"46px"} />
                                    )}
                                </div>
                                <div className="main-download-area" id="main-download-area">
                                    {/*{object.developer_url &&
                                        <a target={"_blank"} href={object.developer_url} className={`download-btn start_download_btn button-29`} role="button">Home Page</a>
                                    }*/}
                                    { object.download_url ? (
                                        <span id="primary-download-btn" className={`${isFixedDownload ? 'scroll-download-btn' : ''}`} onClick={() => downloadFile(object.download_url)}>
                                            <button className={`download-btn start_download_btn button-29 ${downloadClass}`} role="button"><i className="bi bi-cloud-download"></i> { downloadText }</button>
                                        </span>
                                    ) : (
                                        <Skeleton width={"303px"} height={"70px"} />
                                    )}
                                </div>
                            </div>
                        </section>

                        <section className="description-section card-custom">
                            { object.description || <Skeleton count={3} /> }
                        </section>

                        {object.images_list &&
                        <section className="screenshots-section card card-custom">
                            <div className="card-header">Screenshots</div>
                            <div className="card-body">
                                <Slider className="screenshots-list" {...slickSettings}>
                                    {object.images_list.map((item, idx) => (
                                    <span className="item" key={idx} onClick={event => {
                                        setPhotoIndex(idx);
                                        setIsOpenLightbox(true);
                                    }}><img src={Config.mediaDomain + item.thumb} alt="" /></span>
                                    ))}
                                </Slider>

                                {isOpenLightbox && (
                                    <Lightbox
                                        mainSrc={Config.mediaDomain + object.images_list[photoIndex]['large']}
                                        nextSrc={Config.mediaDomain + object.images_list[(photoIndex + 1) % object.images_list.length]['large']}
                                        prevSrc={Config.mediaDomain + object.images_list[(photoIndex + object.images_list.length - 1) % object.images_list.length]['large']}
                                        mainSrcThumbnail={Config.mediaDomain + object.images_list[photoIndex]['thumb']}
                                        nextSrcThumbnail={Config.mediaDomain + object.images_list[(photoIndex + 1) % object.images_list.length]['thumb']}
                                        prevSrcThumbnail={Config.mediaDomain + object.images_list[(photoIndex + object.images_list.length - 1) % object.images_list.length]['thumb']}
                                        onCloseRequest={() => setIsOpenLightbox(false)}
                                        onMovePrevRequest={() =>
                                            setPhotoIndex((photoIndex + object.images_list.length - 1) % object.images_list.length)
                                        }
                                        onMoveNextRequest={() =>
                                            setPhotoIndex((photoIndex + 1) % object.images_list.length)
                                        }
                                    />
                                )}
                            </div>
                        </section>
                        }

                        <section className="details-section card card-custom">
                            <div className="card-header">Details</div>
                            <div className="card-body">
                                {object.information_video &&
                                <div className="video ratio ratio-16x9 mb-4">
                                    <iframe src={object.information_video_url} title={object.title} allowFullScreen></iframe>
                                </div>
                                }
                                { object.content ? (
                                    <div dangerouslySetInnerHTML={{__html: object.content }}></div>
                                ) : (
                                    <div>
                                        <Skeleton count={5} />
                                        <p></p>
                                        <Skeleton count={5} />
                                        <p></p>
                                        <Skeleton count={5} />
                                        <p></p>
                                        <Skeleton count={5} />
                                        <p></p>
                                        <Skeleton count={5} />
                                    </div>
                                )}
                            </div>
                        </section>

                        <section className="more-info-section card card-custom">
                            <div className="card-header">More Information</div>
                            <div className="card-body">
                                { !object.content ? (
                                    <div>
                                        <Skeleton count={5} />
                                    </div>
                                ) : (
                                    <div className="info-list">
                                        <div className="info-item">
                                            <span className="icon"><i className="bi bi-info-circle"></i></span>
                                            <div className="text">
                                                <div className="title">
                                                    Lastest version
                                                </div>
                                                <div className="content">
                                                    {object.last_version ? object.last_version : object.default_value['last_version']}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="info-item">
                                            <span className="icon"><i className="bi bi-folder"></i></span>
                                            <div className="text">
                                                <div className="title">
                                                    Category
                                                </div>
                                                <div className="content">
                                                    <Link to={object.category.link}>{object.category.title}</Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="info-item">
                                            <span className="icon"><i className="bi bi-box"></i></span>
                                            <div className="text">
                                                <div className="title">
                                                    Developed by
                                                </div>
                                                <div className="content">
                                                    <a href="javascript:void(0);">{object.developer}</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="info-item">
                                            <span className="icon"><i className="bi bi-award"></i></span>
                                            <div className="text">
                                                <div className="title">
                                                    License
                                                </div>
                                                <div className="content">
                                                    <span className="badge text-bg-success">FREE</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="info-item">
                                            <span className="icon"><i className="bi bi-display"></i></span>
                                            <div className="text">
                                                <div className="title">
                                                    Operating systems
                                                </div>
                                                <div className="content">
                                                    Windows 7<br/>
                                                    Windows 8<br/>
                                                    Windows 10<br/>
                                                    Windows 11<br/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="info-item">
                                            <span className="icon"><i className="bi bi-globe-americas"></i></span>
                                            <div className="text">
                                                <div className="title">
                                                    Supported languages
                                                </div>
                                                <div className="content">
                                                    English<br/>
                                                    Spanish<br/>
                                                    Portuguese<br/>
                                                    and <span className="languages-list-link">other languages <i className="bi bi-info-circle"></i></span><br/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </section>

                        <section className="second-download-area text-center mt-5 mb-5">
                            <button className="download-btn start_download_btn button-29" onClick={() => downloadFile(object.download_url)} role="button"><i className="bi bi-cloud-download"></i> { downloadText }</button>
                        </section>

                        <section className="related-apps">
                            { object.related_articles &&
                            <div className="regular-app-list border-list">
                                <h2 className="box-title">Related Games</h2>
                                <div className="row gx-4 gy-4">
                                    {object.related_articles.map((item, idx) => (
                                    <div className="col-lg-6" key={idx}>
                                        <Link className="item" to={ item.link }>
                                            <div className="item-head">
                                                <div className="avatar">
                                                    <img src={ item.avatar } alt={ item.title } />
                                                </div>
                                                <div className="info">
                                                    <h3 className="title">{item.title}</h3>
                                                    <div className="stats">
                                                        {item.download_count} downloads
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="item-body">
                                                <div className="description">
                                                    {item.description}
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    ))}
                                </div>
                            </div>
                            }
                        </section>
                    </div>

                    <div className="col-4 col-right">
                        {object.popular_articles ? (
                        <div className="regular-app-list">
                            <h2 className="box-title">Trending Games</h2>
                            {object.popular_articles.map((item, idx) => (
                            <Link className="item" to={item.link} key={idx}>
                                <div className="item-head">
                                    <div className="avatar">
                                        <img src={item.avatar} alt={item.title} />
                                    </div>
                                    <div className="info">
                                        <h3 className="title">{item.title}</h3>
                                        <div className="stats">
                                            {item.download_count} downloads
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            ))}
                        </div>
                        ) : (
                            <div className="regular-app-list">
                                <h2 className="box-title"><Skeleton /></h2>
                                { smallArticleItem() }
                                { smallArticleItem() }
                                { smallArticleItem() }
                                { smallArticleItem() }
                                { smallArticleItem() }
                            </div>
                        )
                        }
                    </div>
                </div>
            </div>
        </main>

        /*<div className="container">
            <Tooltip anchorSelect=".tooltip_other_languages" place="top" style={{width: '300px', zIndex: 9999}}>
                <p className="fw-bold">Available languages:</p>
                <ul className="languages-list">
                    <li>English</li>
                    <li>Arabic</li>
                    <li>Czech</li>
                    <li>Danish</li>
                    <li>German</li>
                    <li>Greek</li>
                    <li>Spanish</li>
                    <li>Finnish</li>
                    <li>French</li>
                    <li>Italian</li>
                    <li>Japanese</li>
                    <li>Korean</li>
                    <li>Dutch</li>
                    <li>Norwegian</li>
                    <li>Polish</li>
                    <li>Portuguese</li>
                    <li>Swedish</li>
                    <li>Turkish</li>
                    <li>Chinese</li>
                    <li>Thai</li>
                    <li>Bengali</li>
                    <li>Urdu</li>
                    <li>Hindi</li>
                </ul>
            </Tooltip>
            <section id="soft-details">
                { object.category.title ? (
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" title="Homepage">Home</Link></li>
                        <li className="breadcrumb-item"><Link to={object.category.link} title={object.category.title}>{object.category.title}</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{object.title}</li>
                    </ol>
                </nav>
                ) : (
                    <Skeleton width={"300px"} height={"24px"} />
                )}
                <div className="row gx-0 mt-4">
                    <div className="col-lg-8 col-left">
                        <div id="header-details" className="d-flex align-items-center justify-content-between">
                            <div>
                                <div className="info-wrapper d-flex flex-row">
                                    { object.avatar ? (
                                        <img className="image" src={object.avatar} alt=""/>
                                    ) : (
                                        <Skeleton width={"80px"} height={"80px"} />
                                    )}
                                    <div className="title-wrapper">
                                        <h1 className="title">{object.title || <Skeleton width={"200px"} />}</h1>
                                        <div className="clearfix star-rating">
                                            { object.star_point ? (
                                            <div className="stars" data-bs-toggle="tooltip" title={`${object.star_point} / 5 Stars`}>
                                                <svg width="20" height="20" viewBox="0 0 940.688 940.688">
                                                    <path
                                                        d="M885.344,319.071l-258-3.8l-102.7-264.399c-19.8-48.801-88.899-48.801-108.6,0l-102.7,264.399l-258,3.8
		c-53.4,3.101-75.1,70.2-33.7,103.9l209.2,181.4l-71.3,247.7c-14,50.899,41.1,92.899,86.5,65.899l224.3-122.7l224.3,122.601
		c45.4,27,100.5-15,86.5-65.9l-71.3-247.7l209.2-181.399C960.443,389.172,938.744,322.071,885.344,319.071z"
                                                    />
                                                </svg>
                                                &nbsp;
                                                <svg width="20" height="20" viewBox="0 0 940.688 940.688">
                                                    <path
                                                        d="M885.344,319.071l-258-3.8l-102.7-264.399c-19.8-48.801-88.899-48.801-108.6,0l-102.7,264.399l-258,3.8
		c-53.4,3.101-75.1,70.2-33.7,103.9l209.2,181.4l-71.3,247.7c-14,50.899,41.1,92.899,86.5,65.899l224.3-122.7l224.3,122.601
		c45.4,27,100.5-15,86.5-65.9l-71.3-247.7l209.2-181.399C960.443,389.172,938.744,322.071,885.344,319.071z"
                                                    />
                                                </svg>
                                                &nbsp;
                                                <svg width="20" height="20" viewBox="0 0 940.688 940.688">
                                                    <path
                                                        d="M885.344,319.071l-258-3.8l-102.7-264.399c-19.8-48.801-88.899-48.801-108.6,0l-102.7,264.399l-258,3.8
		c-53.4,3.101-75.1,70.2-33.7,103.9l209.2,181.4l-71.3,247.7c-14,50.899,41.1,92.899,86.5,65.899l224.3-122.7l224.3,122.601
		c45.4,27,100.5-15,86.5-65.9l-71.3-247.7l209.2-181.399C960.443,389.172,938.744,322.071,885.344,319.071z"
                                                    />
                                                </svg>
                                                &nbsp;
                                                <svg width="20" height="20" viewBox="0 0 940.688 940.688">
                                                    <path
                                                        d="M885.344,319.071l-258-3.8l-102.7-264.399c-19.8-48.801-88.899-48.801-108.6,0l-102.7,264.399l-258,3.8
		c-53.4,3.101-75.1,70.2-33.7,103.9l209.2,181.4l-71.3,247.7c-14,50.899,41.1,92.899,86.5,65.899l224.3-122.7l224.3,122.601
		c45.4,27,100.5-15,86.5-65.9l-71.3-247.7l209.2-181.399C960.443,389.172,938.744,322.071,885.344,319.071z"
                                                    />
                                                </svg>
                                                &nbsp;
                                                <svg width="20" height="20" viewBox="0 0 940.688 940.688">
                                                    <path
                                                        d="M885.344,319.071l-258-3.8l-102.7-264.399c-19.8-48.801-88.899-48.801-108.6,0l-102.7,264.399l-258,3.8
		c-53.4,3.101-75.1,70.2-33.7,103.9l209.2,181.4l-71.3,247.7c-14,50.899,41.1,92.899,86.5,65.899l224.3-122.7l224.3,122.601
		c45.4,27,100.5-15,86.5-65.9l-71.3-247.7l209.2-181.399C960.443,389.172,938.744,322.071,885.344,319.071z"
                                                    />
                                                </svg>
                                                <div className="overlay" style={{width: object.percent_star_point + '%'}}></div>
                                            </div>
                                            ) : (
                                                <Skeleton width={"118px"} height={"24px"} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                { object.content ? (
                                <span id="main-download-btn" onClick={() => downloadFile(object.download_url)} className={`${downloadClass} ${isFixedDownload ? 'fixed-download-btn' : ''} main_download_btn download-btn btn btn-primary btn-lg d-flex align-items-center justify-content-between`} title="Download">
                                    <div className="text text-start">
                                        <span className="main-text">{ downloadText }</span>
                                        <span className="sub-text">{object.download_count} downloads</span>
                                    </div>
                                    <div className="icon"><i className="bi bi-download"></i></div>
                                </span>
                                ) : (
                                    <Skeleton width={"300px"} height={"67px"} />
                                )}
                            </div>
                        </div>
                        { !object.content ? (
                            <div className='my-3'>
                                <Skeleton height={'40px'} />
                            </div>
                        ) : (
                            <ul className="menu-tab">
                                <li className={activeTab === 'information' ? 'active' : ''} onClick={() => setActiveTab('information')} style={{ width: object.how_to_install ? '50%' : '100%' }}>Information</li>
                                {object.how_to_install &&
                                <li className={activeTab === 'how_to_install' ? 'active' : ''} onClick={() => setActiveTab('how_to_install')} style={{ width: object.how_to_install ? '50%' : '100%' }}>How To Install</li>
                                }
                            </ul>
                        )}
                        <div id="details-tabs" className="menu-tab-item tab-infomation" style={{ display: activeTab === 'information' ? 'block' : 'none' }}>
                            <div className="caption">
                                { object.description || <Skeleton count={4} /> }
                            </div>
                            { !object.content ? (
                                <div className='mb-5'>
                                    <Skeleton count={5} />
                                </div>
                            ) : (
                            <ul className="properties">
                                <li><span className="title">Lastest Version:</span><span className="content">{object.last_version ? object.last_version : object.default_value['last_version']}</span></li>
                                <li><span className="title">Operating Systems:</span><span className="content">{object.os ? object.os : object.default_value['os']}</span></li>
                                <li><span className="title">Language:</span><span className="content">English &amp; other languages <i className="bi bi-info-circle tooltip_other_languages" /></span></li>
                                <li><span className="title">License:</span><span className="content"><span className="badge text-bg-success">Free</span></span></li>
                                { object.developer &&
                                <li><span className="title">Developer:</span><span className="content"><a href="#">{object.developer}</a></span></li>
                                }
                            </ul>
                            )}
                            { object.information_video &&
                            <div className="video ratio ratio-16x9 mb-4">
                                <iframe src={object.information_video_url} title="{ object.title }" allowFullScreen></iframe>
                            </div>
                            }
                            { !object.content ? (
                                <Skeleton count={100} />
                            ) : (
                            <div className="content" dangerouslySetInnerHTML={{__html: object.content }}></div>
                            )}
                        </div>
                        <div className="menu-tab-item tab-how-to-install" style={{ display: activeTab === 'how_to_install' ? 'block' : 'none' }}>
                            {object.how_to_install_video &&
                            <div className="video ratio ratio-16x9 mb-4">
                                <iframe src="{{ object.getVideo('how_to_install') }}" title="How to install {{ object.title }}" allowFullScreen></iframe>
                            </div>
                            }
                            <div dangerouslySetInnerHTML={{__html: object.how_to_install }}></div>
                        </div>
                        { object.content &&
                        <div className="d-flex align-items-center justify-content-center mt-5 mb-5">
                            <span onClick={() => downloadFile(object.download_url)} className={`${downloadClass} main_download_btn download-btn btn btn-primary btn-lg d-flex align-items-center justify-content-between`} title="">
                                <div className="text text-start">
                                    <span className="main-text">{ downloadText }</span>
                                    <span className="sub-text">{ object.download_count } downloads</span>
                                </div>
                                <div className="icon"><i className="bi bi-download"></i></div>
                            </span>
                        </div>
                        }
                        { object.related_articles &&
                            <div className="related-softs mt-5">
                                <h3>Related Softs</h3>
                                <div className="category-soft-list row gx-4 gy-4">
                                    {object.related_articles.map((item, idx) => (
                                    <div className="col-lg-6" key={idx}>
                                        <Link className="item download_btn" to={ item.link }>
                                            <div className="top">
                                                <img className="image" src={ item.avatar } alt={ item.title }/>
                                                <div className="info">
                                                    <h3 className="title">{item.title}</h3>
                                                    <span className="count">{item.download_count} downloads</span>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="description">{item.description}</div>
                                                <span className="action btn btn-primary btn-sm">View Details</span>
                                            </div>
                                        </Link>
                                    </div>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                    <div className="col-lg-4 col-right">

                        {object.content ? (
                            <div>
                                <h4 className="">Popular Softs</h4>
                                <div className="category-soft-list row gy-4">
                                    {object.popular_articles.map((item, idx) => (
                                        <div className="col-lg-12" key={idx}>
                                            <Link title="" className="item download_btn" to={item.link}>
                                                <div className="top">
                                                    <img className="image" src={item.avatar} alt={item.title}/>
                                                    <div className="info">
                                                        <h3 className="title">{item.title}</h3>
                                                        <span className="count">{item.download_count} downloads</span>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="description">{item.description}</div>
                                                    <span className="action btn btn-primary btn-sm">View Details</span>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="category-soft-list row gy-4">
                                <h4 className=""><Skeleton /></h4>
                                { articleItem() }
                                { articleItem() }
                                { articleItem() }
                                { articleItem() }
                                { articleItem() }
                            </div>
                        )
                        }
                    </div>
                </div>
            </section>
        </div>*/
    );
}
